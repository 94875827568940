<template>
  <div class="id">
    <div style="padding:10px 20px;width:100%">
      <div style="text-align:left;display: inline-block;width: 40%;">
        <img src="/assets/logo.png" alt="" style="width:300px;height:50px">
      </div>
      <div style="text-align:right;display: inline-block;width: 55%;">
        <img  :src="
              userInfo.avatarPath
                ? 'http://qyapi.ruanwe.com/' + userInfo.avatarPath
                : userImg
            " alt=""
          style="width:30px;height:30px;margin-right:15px;border-radius: 50% 50%;">
        <a-dropdown>
          <a style="color:#fff" @click.prevent>
            {{ userInfo.username }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click="changePassword">修改密码</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="exit">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div style="padding:15px 0;">
      <a-row type="flex" justify="space-around">
        <a-col :span="15">
          <div class="tlDiv">
            <div class="tlDiv_top">
              <a-row>
                <a-col :span="2">
                  <img :src="
                      userInfo.avatarPath
                        ? 'http://qyapi.ruanwe.com/' + userInfo.avatarPath
                        : userImg
                    " alt=""
                    style="width:64px;height:64px;margin-top: 30px;margin-left: 30px;border-radius: 50% 50%;" />
                </a-col>
                <a-col :span="18">
                  <div style="display:inline-block;margin-left:20px;margin-top:25px">
                    <div style="font-size: 15px;"> {{ userInfo.username }}，
                      {{ ho < 9 ? '早上好' : (ho < 12 ? '上午好' : (ho < 15 ? '中午好' : (ho < 19 ? '下午好' : '晚上好'))) }}！
                    </div>
                    <div class="type" style="margin-top:10px">身份：管理员</div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div style="background: rgba(255, 255, 255, 0.6);width:100%;height:1px;margin-bottom:35px">
            </div>
            <div class="tlDiv_bttom">
              <div class="icon_div" style="background: linear-gradient(204.88deg, #FDF5F0 3.17%, #FEEAE4 95.89%);"
                @click="click(1)">
                <img src="/assets/safetyManagement.png" alt="">
                <p>
                  安全管理
                </p>
              </div>
              <div class="icon_div" style="background: linear-gradient(180deg, #F4F5FC 0%, #EBEFFF 100%);"
                @click="click(2)">
                <img src="/assets/database.png" alt="">
                <p>
                  培训管理
                </p>
              </div>
              <div class="icon_div" style="background:  linear-gradient(204.64deg, #FFFEF8 3.16%, #F8F5E9 95.6%);"
                @click="click(3)">
                <img src="/assets/system.png" alt="">
                <p>
                  体系管理
                </p>
              </div>
              <div class="icon_div" style="background: linear-gradient(205.18deg, #FAFFFC 3.6%, #E6F9F0 96.45%);"
                @click="click(4)">
                <img src="/assets/certificate.png" alt="">
                <p>
                  证书管理
                </p>
              </div>
              <div class="icon_div" style="background:  linear-gradient(180deg, #F3F9FE 0%, #E7F4FB 100%);"
                @click="click(5)">
                <img src="/assets/maintenance.png" alt="">
                <p>
                  机务管理
                </p>
              </div>
              <div class="icon_div" style="background: linear-gradient(204.88deg, #FDF0FB 3.17%, #F6E4FE 95.89%);"
                @click="click(6)">
                <img src="/assets/personnel.png" alt="">
                <p>
                  人员管理
                </p>
              </div>
              <div class="icon_div" style="background:linear-gradient(204.88deg, #FDF3F0 3.17%, #FEE4E4 95.89%);"
                @click="click(7)">
                <img src="/assets/permissions.png" alt="">
                <p>
                  权限管理
                </p>
              </div>
              <div class="icon_div" style="background:linear-gradient(204.88deg, #F0EEFF 3.17%, #E7E3FF 95.89%);"
                @click="click(8)">
                <img src="/assets/cost.png" alt="">
                <p>
                  费用管理
                </p>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8" >
          <div class="trDiv">
            <div class="trDiv_title" style="">通知公告</div>
            <div class="trDiv_div">
              <div v-for="(item, index) in  ajbNotificationInfo" :key="index">
                <div class="trDiv_item" @click="click_notice(item)">
                  <span style="float:left">{{ item.title }}</span>
                  <span style="float:right">{{ item.createdTime }}</span>
                </div>
                <div style="border:1px dashed #fff;margin:3px 0 "></div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-row type="flex" justify="space-around">
      <a-col :span="15">
        <a-row type="flex" justify="space-between">
          <a-col :span="12">
              <div class="bl_Bdiv">
              <div style="display:inline-block; font-weight: 700;font-size: 18px;line-height: 22px;color: #333333;">提醒</div>
              <div style="width:100%;height:1px;background:#fff;margin-top:5px;"></div>
              <div v-for="(item,index) in ajbNoticeInfo" :key="index">
                <div class="Bdiv_item"
                  :style="{background:
                    'linear-gradient(90deg, #fcf0f0 0%, rgba(255, 255, 255, 0) 100%)'
                  }"
                >
                <div class="Bdiv_item_stick" :style="{background: '#EA4033'}"></div>
                <div class="Bdiv_item_top">{{ item.title }}</div>
                <div class="Bdiv_item_bottom">
                  <span :style="{color: '#EA4033'}">{{ item.noticeContent }}</span>
                 </div>
                <a-button  type="primary" class="button" @click="LookClick(item)">已读</a-button>
                </div>
              </div>
            </div>
            <div class="bl_Tdiv">
              <div style="font-weight: 700;font-size: 18px;line-height: 22px;color: #333333;">快捷访问</div>
              <div style="width:100%;height:1px;background:#fff;margin-top:5px;"></div>
              <a-row type="flex" justify="space-between" align="middle" style="text-align:center;margin:20px 0px">
                <template v-for="(item,index) in ajbWebsiteInfo" :key="index">
                  <a-col :span="7" class="col" @click="urlClick(item.url)">
                    <span :title="item.title"> {{ item.title }}</span>
                  </a-col>
                </template>
              </a-row>
            </div>
          </a-col>
          <a-col :span="11">
            <div class="bB_div">
              <div style="font-weight: 700;font-size: 18px;line-height: 22px;color: #333333;">船舶动态</div>
              <div style="width:100%;height:1px;background:#fff;margin-top:5px;"></div>
              <div v-for="(item, index) in ajbVslStatusInfo" :key="index">
                <div class="bB_div_item" style="background: rgba(255, 255, 255, 0.4);">
                  <div class="bB_div_top">{{ item.vslnm }}</div>
                  <div class="bB_div_bottom">
                    <span v-if="item.updatedTime">{{ item.updatedTime }}更新状态</span>
                    <span v-else>当前状态未更新</span>
                  </div>
                  <div class="bB_div_div" style="background: rgba(71, 142, 248, 0.1); color: #478ef8;"
                    v-if="item.status == '在航'">{{ item.status }}</div>
                  <div class="bB_div_div" style="background:  rgba(19, 200, 59, 0.1); color: #13C83B;"
                    v-if="item.status == '锚地'">{{ item.status }}
                  </div>
                  <div class="bB_div_div" style="background: rgba(252, 146, 88, 0.1); color: #FC9258;"
                    v-if="item.status == '加油'">{{ item.status }}
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="8">
        <div class="bC_div">
          <a-calendar />
        </div>
      </a-col>
    </a-row>
    <a-modal v-model:visible="visible" title="公告详情" width="1000px" @ok="handleOk" footer>
      <div class="modal">
        <h2 style="text-align:center">{{ titleInfo.title }}</h2>
        <h4>{{ titleInfo.content }}</h4>
      </div>
    </a-modal>
    <a-modal v-model:visible="changePasswordVisible" title="修改密码" @ok="changePasswordHandleOk" :confirmLoading="confirmLoading">
      <a-form :label-col="labelCol" ref="formRef" :model="form" :rules="loginRules" v-bind="layout">
        <a-form-item label="旧密码" name="oldPswd" label-width="80px">
          <a-input-password v-model:value="form.oldPswd" autocomplete="off" placeholder="请输入旧密码" />
        </a-form-item>
        <a-form-item label="新密码" name="newPswd">
          <a-input-password v-model:value="form.newPswd" autocomplete="off" placeholder="请输入新密码" />
        </a-form-item>
        <a-form-item label="确认密码" name="confirmPswd">
          <a-input-password v-model:value="form.confirmPswd" autocomplete="off" placeholder="请输入确认密码" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script >
import {
  defineComponent,
} from "vue";
import LoginStore from "@/store/modules/login";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { Modal } from 'ant-design-vue';
import { getCurrentUser } from "@/api/login";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { DownOutlined } from '@ant-design/icons-vue';
import { ajbWebsite, ajbNotice, ajbVslStatus, ajbNotification,updateStatus_put } from '@/api/workapi'
import { ranweiChangePswd } from '@/api/login1'
import { notification } from 'ant-design-vue';
export default defineComponent({
  name: "questionType",
  components: {
    DownOutlined,
  },
  props: {
    edit: Boolean,
    typeOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    var pswdValidator = async (rule, value) => {
      const passwordreg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}/
      if (!value) {
        return Promise.reject('请输入密码');
      } else {
        if (!passwordreg.test(value)) {
          return Promise.reject('密码为8-20位,且同时包含数字、大写字母、小写字母、特殊符号');
        } else {
          return true
        }
      }
    };
    var newPswdValidator = async (rule, value) => {
      const passwordreg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}/
      if (!value) {
        return Promise.reject('请输入密码');
      } else {
        if (!passwordreg.test(value)) {
          // return true
          return Promise.reject('密码为8-20位,且同时包含数字、大写字母、小写字母、特殊符号');
        } else {
          return true
        }
      }
    };
    var confirmPswdValidator = async (rule, value) => {
      if (!value) {
        return Promise.reject('请输入密码');
      } else {
        if (this.form.newPswd===value) {
          return true 
        } else {
          return Promise.reject('两次密码不一致');
        }
      }
    };
    return {
      labelCol: { span: 4 },
      confirmLoading:false,
      form: {
        //旧密码
        oldPswd: "",
        //新密码
        newPswd: "",
        //确认密码
        confirmPswd: "",
      },
      loginRules: {
        oldPswd: [{ validator: pswdValidator, required: true, }],
        newPswd: [{ validator: newPswdValidator, required: true,}],
        confirmPswd: [{ required: true, validator: confirmPswdValidator }],
      },
      changePasswordVisible: false,
      titleInfo: '',
      ajbNotificationInfo: [],
      ajbNoticeInfo: [],
      ajbWebsiteInfo: [],
      ajbVslStatusInfo: [],
      visible: false,
      userImg: 'http://8.142.218.12:9000/test/safety-20221112-5e9ea56ecdf54670a5530a533d343ba0.png',
      userInfo: {},
      ho: new Date().getHours()
    };
  },
  created() {
    getCurrentUser({}).then((userInfo) => {
      this.userInfo = userInfo.data
    })
    // 通知公告
    ajbNotification({ "currentPage": 1, "pageSize": 1000000, }).then(res => {
      this.ajbNotificationInfo = res.data
    })
    //常用网站
    ajbWebsite({ "currentPage": 1, "pageSize": 1000000, }).then(res => {
      this.ajbWebsiteInfo = res.data
    })
    //提醒表
    ajbNotice({ "currentPage": 1, "pageSize": 1000000, }).then(res => {
      this.ajbNoticeInfo=res.data
    })
    //船舶状态
    ajbVslStatus({ "currentPage": 1, "pageSize": 5, }).then(res => {
      this.ajbVslStatusInfo = res.data
    })
  },
  computed: {

  },
  methods: {
    LookClick(item){
      updateStatus_put({id:item.id}).then(res=>{
        ajbNotice({ "currentPage": 1, "pageSize": 1000000, }).then(res => {
          this.ajbNoticeInfo=res.data
        })
      })
    },
    changePassword() {
      this.changePasswordVisible = true
    },
    changePasswordHandleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading=true
        ranweiChangePswd({...this.form ,userId:this.userInfo.userId}).then(res=>{
          if(res.data.canChange){
           this.$message.success('修改密码成功');
          const loginStore = getModule(LoginStore, store)
           loginStore.layout().then(() => this.$router.push('/login'))
           this.changePasswordVisible = false
          }else{
            notification.error({
              message: '修改密码失败',
              description:res.data.SERVER_MSG
            });
          }
          this.confirmLoading=false
        })
        .catch((e) => {
          console.log(e);
        })
      })
      .catch((e) => {
        console.log(e);
      })
    },
    urlClick(url) {
      console.log(url);
      window.open(url)
    },
    click_notice(val) {
      this.visible = true
      this.titleInfo = val
    },
    exit() {
      const loginStore = getModule(LoginStore, store)
      Modal.confirm({
        title: '退出确认',
        content: '确认退出系统吗？',
        onOk: () => {
          loginStore.layout().then(() => this.$router.push('/login'))
        }
      })
    },
    click(val) {
      if (val == 1) {
        window.open('http://47.92.25.14:8080/pg/index.htm?method=indexSec&menuType=safe&ph=' + cookies.get('phone') + '&pd=' + cookies.get('password'))
      }
      if (val == 2) {
        this.$router.push('/question/type')
      }
      if (val == 3) {

      }
      if (val == 4) {
        window.open('http://47.92.25.14:8080/pg/index.htm?method=indexSec&menuType=cert&ph=' + cookies.get('phone') + '&pd=' + cookies.get('password'))
      }
      if (val == 5) {
        window.open('http://47.92.25.14:8080/pg/index.htm?method=indexSec&menuType=root&ph=' + cookies.get('phone') + '&pd=' + cookies.get('password'))
      }
      if (val == 6) {
        window.open('http://47.92.25.14:8080/pg/index.htm?method=indexSec&menuType=hrf&ph=' + cookies.get('phone') + '&pd=' + cookies.get('password'))
      }
      if (val == 7) {
        window.open('http://47.92.25.14:8080/pg/index.htm?method=indexSec&menuType=sys&ph=' + cookies.get('phone') + '&pd=' + cookies.get('password'))
      }
      if (val == 8) {
        window.open('http://47.92.25.14:8080/pg/index.htm?method=indexSec&menuType=fee&ph=' + cookies.get('phone') + '&pd=' + cookies.get('password'))
      }
    },
    Icon(val) {
      return '/assets/' + val
    }

  },
});
</script>
<style lang="less">
.modal {
  padding: 10px;
  height: 600px;
  overflow: auto;
}

.id {
  width: 100%;
  padding: 10px;
  background-image: url("workbench.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.tlDiv {
  padding: 10px;
  width: 100%;
  height: 400px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;

  &_top {
    height: 120px;

    .type {
      padding: 5px 15px;
      border-radius: 15px 15px;
      border: 1px solid rgba(71, 142, 248, 1);
      background: rgba(71, 142, 248, 0.1);
      color: #478ef8;
      display: inline-block;
      margin-right: 10px;
    }
  }

  &_bttom {
    display: flex;
    justify-content: space-around;

    .icon_div {
      cursor: pointer;
      width: 130px;
      height: 190px;
      border-radius: 10px;
      display: inline-block;
      text-align: center;
      padding-top: 10px;

      img {
        margin-top: 35px;
        width: 56px;
        height: 56px;
      }

      p {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 600;
      }
    }

    .icon_div:hover {
      p {
        color: #478ef8;
      }
    }
  }
}

.trDiv {
  width: 100%;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%);

  border: 1px solid #ffffff;
  border-radius: 4px;

  .trDiv_title {
    padding: 7px;
    height: 40px;
    width: 100%;
    font-size: 16px;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 4px 4px 0px 0px;
  }

  .trDiv_div {
    height: 357px;
    overflow: auto;

    .trDiv_item {
      padding: 10px;
      font-size: 14px;
      height: 40px;
      cursor: pointer;
    }

    .trDiv_item:hover {
      color: #478ef8;
    }
  }
}

.bl_Tdiv {
  margin-bottom: 10px;
  width: 100%;
  height: 400px;
  padding: 20px;
  overflow: auto;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;

  .col {
    margin-top: 10px;
    line-height: 30px;
    height: 40px;
    width: 140px;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #ffffff;
    backdrop-filter: blur(3px);
    border-radius: 10px;
  }

  .col:hover {
    cursor: pointer;
    background: rgba(71, 142, 248, 0.1);
    color: #478ef8;
  }
}

.bl_Bdiv {
  margin-bottom: 10px;
  width: 100%;
  height: 400px;
  padding: 20px;
  overflow: auto;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;

  .Bdiv_item {
    padding: 20px;
    margin: 30px 0 0 0px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    .button{
      position: absolute;
      top:25px;
      right:0;
    }
    .Bdiv_item_stick {
      position: absolute;
      width: 2px;
      height: 30px;
      left: 10px;
      top: 22px;
      border-radius: 4px;
    }

    .Bdiv_item_top {
      width: 85%;
      margin-left: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #333333;
    }

    .Bdiv_item_bottom {
      width: 85%;
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      line-height: 20px;
      color: rgba(102, 102, 102, 1);

      span {
        color: #ea4033;
      }
    }
  }
}

.bB_div {
  padding: 20px;
  width: 100%;
  height: 805px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;

  .bB_div_item {
    position: relative;
    margin: 35px 0 0 0;
    padding: 30px;
    width: 100%;
    height: 140px;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #ffffff;
    backdrop-filter: blur(3px);
    border-radius: 10px;

    .bB_div_top {
      margin-top: 18px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #333333;
    }

    .bB_div_bottom {
      margin-top: 10px;
      font-weight: 300;
      font-size: 12px;
      line-height: 22px;
      color: #999999;
    }

    .bB_div_div {
      position: absolute;
      width: 80px;
      height: 30px;
      right: 30px;
      top: 55px;
      text-align: center;
      line-height: 30px;
      border-radius: 4px;
    }
  }
}

.bC_div {
  width: 100%;
  height: 805px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;
}
</style>
