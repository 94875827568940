import axios from 'axios'
import qs from 'qs'
const md5 = require('js-md5')
/**
 * 修改密码
 */
 export function ranweiChangePswd(params:any) {
  // var data = {
  //   userId:params.userId,
  //   oldPswd: md5(toString(params.oldPswd)),
  //   newPswd:md5(toString(params.newPswd)), 
  //   confirmPswd:md5(toString(params.confirmPswd)),
  // }
  var data = {
    userId:params.userId,
    oldPswd: params.oldPswd,
    newPswd:params.newPswd, 
    confirmPswd:params.confirmPswd,
  }
  return axios.post("http://47.92.25.14:8080/pg/logon.htm?method=ranweiChangePswd",qs.stringify(data));
}



